/* eslint react/destructuring-assignment: 'off' */
/* eslint react/no-access-state-in-setstate: 'off' */
/* eslint no-unused-vars: 'off' */

import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import styled from 'styled-components';

const FormWrapper = styled.div`
  input::placeholder {
    color: var(--light_grey);
    font-size: 1.6rem;
  }

  input[type='email'] {
    background-color: transparent;
    background: transparent;
    border-radius: 50px;
    border: 2px solid var(--light_grey);
    padding: 0 20px;
    font-family: 'Montserrat';
    font-size: 1.6rem;
    width: 340px;
    max-width: 50vw;
    height: 53px;
    /* margin-right: 40px; */
  }

  input[type='email']:focus,
  input[type='email']:focus-within {
    outline: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    background-color: white !important;
    -webkit-text-fill-color: black;
    /* box-shadow: 0 0 0px 1000px var(--_93Yellow) inset; */
    /* -webkit-box-shadow: 0 0 0px 1000px var(--_93Yellow) inset; */
    transition: background-color 5000s ease-in-out 0s;
  }

  h1 {
    margin: 80px auto 60px;
    line-height: 1;
    font-weight: 900;
    color: #242424;
  }

  p {
    font-size: 1.6rem;
    color: var(--light_grey);
    max-width: 50ch;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    margin-top: 20px;
  }

  p.error-desc {
    color: var(--light_grey);
    margin-bottom: 0;
    margin-top: 0;
  }

  input[type='submit'] {
    border-radius: 55px;
    border: 2px solid var(--aeriz_blue);
    background-color: var(--aeriz_blue);
    font-family: 'Montserrat';
    height: 53px;
    font-size: 1.6rem;
    font-weight: 800;
    letter-spacing: var(--letter_spacing);
    text-transform: uppercase;
    color: white;
    cursor: pointer;
    transition: 0.3s ease-in-out all;
    padding: 10px 40px;
    margin: 40px 0 40px 16px;
  }

  input[type='submit']:hover {
    color: var(--aeriz_blue);
    background-color: white;
    transition: 0.3s ease-in-out all;
  }
  @media only screen and (max-width: 530px) {
    input[type='submit'] {
      margin: 40px;
    }
    h1 {
      margin: 40px 0 30px;
    }
  }
`;

export default class EmailCollectionForm extends React.Component {
  constructor() {
    super();
    this.state = { email: '', result: null };
  }

  _handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(e);
    const result = await addToMailchimp(this.state.email, {
      SOURCE: 'signup.aeriz.com',
    });
    this.setState({ result });
    localStorage.setItem(this.state.email, 'true');
  };

  handleChange = (event) => {
    this.setState({ email: event.target.value });
  };

  render() {
    let heading;
    let message;
    let formVisibility;
    let ErrorMessage;

    function extractEmails(text) {
      return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
    }

    if (this.state.result === null) {
      heading = 'Stay in our rotation.';
      message = (
        <p>
          Sign up and be the first to know about new releases, exclusive merch
          drops, and more. We promise to only bother you when we’ve got
          something really good.
        </p>
      );
    } else if (this.state.result.result === 'success') {
      formVisibility = { display: 'none' };
      heading = 'Thank you for rolling with us.';
      message = <p>Sit back, spark up, and we’ll talk to you soon.</p>;
    } else if (this.state.result.result === 'error') {
      heading = 'Uh oh — there was an error!';
      message = (
        <p>
          It looks like there was an issue with your email. Please make sure
          everything is spelled correctly and try again.
        </p>
      );
      // ErrorMessage = this.state.result.msg.substring(
      //   0,
      //   this.state.result.msg.indexOf('<')
      // );

      const signupEmail = extractEmails(this.state.result.msg);
      if (signupEmail != null) {
        ErrorMessage = `Looks like ${signupEmail} is already on the list- right near the top.`;
      } else {
        ErrorMessage = this.state.result.msg;
      }
    } else {
      heading = 'Uh oh — there was an error!';
      message = (
        <p>
          It looks like there was an issue with your email. Please make sure
          everything is spelled correctly and try again.
        </p>
      );
      ErrorMessage = this.state.result.msg;
    }

    return (
      <FormWrapper>
        <h1>{heading}</h1>
        {message}
        <form
          onSubmit={this._handleSubmit}
          style={formVisibility}
          autoComplete="off"
        >
          <input
            id="aeriz-email"
            label="Email"
            type="email"
            inputMode="email"
            name="email"
            autoComplete="off"
            placeholder="Email Address"
            onChange={this.handleChange}
          />
          <input type="submit" value="Submit" />
        </form>
        <p className="error-desc">{ErrorMessage}</p>
      </FormWrapper>
    );
  }
}
