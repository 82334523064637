import React from 'react';
import styled from 'styled-components';
import Logo from '../components/Logo.js';
import EmailCollectionForm from '../components/EmailCollectionForm';
import Stamp from '../components/Stamp';
import LegalDisclaimer from '../components/LegalDisclaimer';

const PageWrapper = styled.section`
  /* width: 100vw; */
  min-height: 100vh;
  display: grid;
  place-content: center;
  text-align: center;
  padding: 0 24px;
  background-color: var(--_93Yellow);
`;

const ContentWrapper = styled.div`
  /* background-color: blue; */
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;
  justify-content: center;
  display: grid;
`;

export default function HomePage() {
  return (
    <PageWrapper className="aeriz-email-capture">
      <ContentWrapper>
        <Logo />
        <EmailCollectionForm />
        <Stamp />
        <LegalDisclaimer />
      </ContentWrapper>
    </PageWrapper>
  );
}
